import { render, staticRenderFns } from "./new_resetpwd.vue?vue&type=template&id=5d4591ee&scoped=true&"
import script from "./new_resetpwd.vue?vue&type=script&lang=js&"
export * from "./new_resetpwd.vue?vue&type=script&lang=js&"
import style0 from "./new_resetpwd.vue?vue&type=style&index=0&id=5d4591ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d4591ee",
  null
  
)

export default component.exports