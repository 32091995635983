<template>
    <div class="password-box">
        <h1>
          <div style="display: inline-block; cursor: pointer" @click="login">
            <a-icon type="left" />
          </div>
          重置密码
        </h1>
      <div class="form">
        <div class="form-item">
          <div class="label">手机号</div>
          <div class="input-wrap">
            <a-input size="large" v-model="account" placeholder="请输入手机号码"/>
          </div>
        </div>
        <div class="form-item">
          <div class="input-wrap">
            <div class="label">{{ status ? "输入验证码" : "验证码" }}</div>
            <div class="input-wrap">
              <div v-if="!status" class="rang-wrap">
                <jc-range
                  :status="status"
                  :account="account"
                  :key="rangeKey"
                  :successFun="onMpanelSuccess"
                  :errorFun="onMpanelError"
                />
              </div>
              <a-input
                v-else
                v-model="verifyCode"
              >
                <a-button
                  slot="addonAfter"
                  size="large"
                  style="height: 44px; width: 100px;border-radius: 0;"
                  type="button"
                  @click="getCode"
                  :disabled="buttonText !== '重新发送'"
                  >{{ buttonText }}</a-button
                >
              </a-input>
            </div>
            
          </div>
        </div>
        <div class="form-item">
          <div class="label">请输入新密码</div>
          <div class="input-wrap">
            <a-input-password size="large" style="font-size: 14px;" v-model="password" placeholder="请输入新密码"/>
          </div>
        </div>
        <div class="form-item">
          <div class="label">请再次输入新密码</div>
          <div class="input-wrap">
            <a-input-password size="large" style="font-size: 14px;" v-model="repassword" placeholder="请再次输入新密码" />
          </div>
        </div>
        <div class="btns">
          <a-button type="primary" size="large" style="font-size:14px" block @click="register"
            >确定</a-button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import JcRange from "@/components/JcRange.vue";
  import { getCookie, isPhone, messageTips, setCookie } from "../../until/tools";
  var timer;
  var seconds;
  export default {
    name: "Register",
     components: { JcRange },
    data() {
      return {
        buttonText: "重新发送",
        account: "",
        verifyCode: "",
        password: "",
        repassword: "",
        rangeKey:0,
        status:false,
      };
    },
      watch: {
      account() {
        this.status = false;
        this.rangeKey = Math.random();
        this.verifyCode = "";
      },
    },
    methods: {
      getCode() {
        if (this.account === "") {
          messageTips("请输入邮箱账号", 2); 
          return false;
        }
        if (!isPhone(this.account)) {
          messageTips("手机号码格式不正确", 2);
          return false;
        }
        let params = {
          account: this.account,
        };
        this.$http.getSign(params).then((res) => {
          this.status=true
          if (res && res.sign) {
            this.$http.sendCode({ sign: res.sign, ...params }).then((res) => {
              this.setCountDown(59);
              this.verifyCode=res.verifyCode
            });
          }
        });
      },
          onMpanelSuccess() {
        if (!timer) {
          this.getCode();
        } else {
          this.status = true;
        }
      },
      onMpanelError() {},
      setCountDown(num) {
        seconds = num;
        this.buttonText = num + "s";
        timer = setInterval(() => {
          seconds -= 1;
          this.buttonText = seconds + "s";
          if (seconds < 0) {
            clearInterval(timer);
            this.buttonText = "重新发送";
          }
        }, 1000);
      },
      register() {
        if (this.account === "") {
          messageTips("请输入邮箱账号", 2);
          return false;
        }
        if (!isPhone(this.account)) {
          messageTips("手机号码格式不正确", 2);
          return false;
        }
        if (this.verifyCode === "") {
          messageTips("请输入验证码", 2);
          return false;
        }
        if (this.password === "") {
          messageTips("请输入密码", 2);
          return false;
        }
        if (this.repassword === "") {
          messageTips("请确认密码", 2);
          return false;
        }
        if (this.repassword !== this.password) {
          messageTips("两次密码不一致", 2);
          return false;
        }
        let params = {
          account: this.account,
          verifyCode: this.verifyCode,
          password: this.password,
        };
        this.$http.resetPwd(params).then((res) => {
          if (res) {
            if (res.success) {
              messageTips(res.message, 3);
              this.$router.push("/login");
            } else {
              messageTips(res.message, 1);
            }
          }
        });
      },
      login() {
        this.$router.push("/login");
      },
    },
    mounted() {
      let cookieSeconds = getCookie("seconds");
      if (cookieSeconds) {
        let num = parseInt(cookieSeconds);
        this.setCountDown(num);
      }
  
      window.onbeforeunload = () => {
        if (seconds > 0) {
          setCookie("seconds", seconds, { time: seconds }, true);
        }
      };
    },
    beforeDestroy() {
      if (timer) {
        clearInterval(timer);
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .password-box{
    margin:2rem auto 0px;
    background: #fff;
    position: relative;
    z-index: 99;
    width:4.5rem;
    h1{
        font-size: 22px;
    }
    .form{
        .form-item{
            .label{
                margin-top: 0.15rem;
                font-size:14px;
                height: 0.5rem;
                line-height: 0.5rem;
                color: #333;
            }
        }
        .btns{
            margin-top:0.3rem;
        }
    }
  }
  .ant-input-lg{
    font-size: 14px;
    height:40px;
   }
   .ant-input-password{
        /deep/.ant-input-lg{
            font-size: 14px;
        }
    }
  </style>